import { Controller } from "@hotwired/stimulus"
import { Tab } from "bootstrap"

export default class extends Controller {
    static targets = ['tab']

    connect() {
        this.tabTargets.forEach(triggerEl => {
            const tabTrigger = new Tab(triggerEl)
            triggerEl.addEventListener('click', e => {
                e.preventDefault()
                tabTrigger.show()
            })
        })
    }
}
