import {get} from 'lodash-es'
import SlimSelect from 'slim-select'

export const formatOptions = (propertyPaths, selected) => option => ({
  text: propertyPaths.map(v => get(option, v)).join(' '),
  value: option.id,
  selected: selected.includes(option.id)
})

export const buildSelect = options =>
  new SlimSelect({
    settings: {
      placeholder: 'Seleccione',
      searchPlaceholder: 'Busca',
      searchingText: 'Buscando...',
      searchText: 'Sin resultados',
      allowDeselect: true,
    },
    searchFilter: (option, search) =>
      option.text.match(new RegExp(search.replaceAll('%', '.*'), 'i')),
    ...options
  })
