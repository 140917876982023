import { Controller } from "@hotwired/stimulus"
import { buildSelect, formatOptions } from '../select_utils'

export default class extends Controller {
  static targets = ['element']
  static values = {
    url: String,
    texts: Array,
    selected: Array,
    addable: Boolean
  }

  connect() {
    // Skip initialization if SlimSelect is already constructed
    // See: https://github.com/brianvoe/slim-select/issues/317#issuecomment-1049154579
    if (this.elementTarget.dataset.id) return

    this.elementTarget.classList.remove('invisible')

    const format = formatOptions(this.textsValue, this.selectedValue)

    const selectOptions = { select: this.elementTarget }

    selectOptions.events ||= {}
    if (this.urlValue) {
      selectOptions.events.search = (text, _currentData) =>
        new Promise((resolve, reject) => {
          if (text.length === 0) return reject(false)

          this.fetchData(text)
            .then(json => resolve(json.map(format)))
            .catch(e => e === 403 ? reject('Más de 500 resultados') : reject('Error'))
        })
    }
    if (this.addableValue) {
      selectOptions.events.addable = (value) => value.trim()
    }

    const select = buildSelect(selectOptions)
    // selectedValue must be already present in the list of options
    if (this.selectedValue.length > 0) {
      select.set(this.selectedValue)
    }
  }

  fetchData(text) {
    return fetch(`${this.urlValue}${encodeURIComponent(text)}${encodeURIComponent('%')}`)
      .then(r => r.status === 403 ? Promise.reject(403) : r.json())
  }
}
