import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['scrollTop', 'scrollTopContent', 'scrollBottom']

  connect() {
    this.scrollTopContentTarget.style.width = `${this.scrollBottomTarget.querySelector(':first-child').scrollWidth}px`
    this.scrollTopContentTarget.style.height = '1px'
    this.scrollTopTarget.addEventListener('scroll', () => this.scrollBottomTarget.scrollLeft = this.scrollTopTarget.scrollLeft)
    this.scrollBottomTarget.addEventListener('scroll', () => this.scrollTopTarget.scrollLeft = this.scrollBottomTarget.scrollLeft)
  }
}
