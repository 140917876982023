import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "bg" ]

  change(event) {
    const imageName = event.target.value ? event.target.value?.replaceAll('/', '-') : 'all'
    this.bgTarget.style.backgroundImage = `url("/images/${imageName}.jpg")`
  }
}
