import 'trix'
import '@rails/actiontext'
import '@hotwired/turbo-rails'
import '../controllers/index'

import { Dropdown, Collapse } from 'bootstrap'

document.addEventListener('turbo:load', () => {
    Array.from(document.querySelectorAll('.dropdown-toggle')).forEach(n => {
        new Dropdown(n)
    })
    Array.from(document.querySelectorAll('.collapse')).forEach(n => {
        new Collapse(n, { toggle: false })
    })
})
