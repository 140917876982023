import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['file', 'button']

  connect() {
    this.buttonTarget.addEventListener('click', () => this.fileTarget.click())
    this.fileTarget.addEventListener('change', () => this.element.submit())
  }
}
