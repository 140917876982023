import { Controller } from '@hotwired/stimulus'
import { throttle } from 'lodash-es'

export default class extends Controller {

  connect() {
    // Use requestSubmit() to work with turbo. See: https://stackoverflow.com/a/69537709
    this.autoSubmit = throttle(() => this.element.requestSubmit(), 500, { leading: false })
  }

  input() {
    this.autoSubmit()
  }
}
